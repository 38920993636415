import { ChainId, ERC20Token, WZBC } from '@pancakeswap/sdk'

export const nautilusTestnetTokens = {
  wzbc: WZBC[ChainId.NAUTILUS_TESTNET],
  btc: new ERC20Token(ChainId.NAUTILUS_TESTNET, '0x6B643beBbb9951703A32Ad6Ec2f589348BDA4a08', 18, 'BTC', 'BTC'),
  eth: new ERC20Token(ChainId.NAUTILUS_TESTNET, '0xF647E45B87610af844F6b92Cc1E1a386640aBAe8', 18, 'ETH', 'ETH'),
  usdt: new ERC20Token(ChainId.NAUTILUS_TESTNET, '0x390aC9d456c839C1796053Ac903b4D0815fBd5b0', 18, 'USDT', 'USDT'),
  usdc: new ERC20Token(ChainId.NAUTILUS_TESTNET, '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C', 18, 'USDC', 'USDC'),
  dai: new ERC20Token(ChainId.NAUTILUS_TESTNET, '0x1bcE0F7B2Df88785964F5AC8630eCd538d2E782E', 18, 'DAI', 'DAI'),
}
