import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.NAUTILUS_TESTNET]: 'triton',
} satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

export const NAUTILUS_CHAIN_INFO = {
  chainId: '0x1637a',
  chainIdinDecimal: 91002,
  chainName: 'Triton Testnet',
  network: 'Triton',
  nativeCurrency: {
    decimals: 18,
    name: 'Zebec',
    symbol: 'ZBC',
  },
  rpcUrls: ['https://triton.api.nautchain.xyz'],
  blockExplorerUrls: ['https://triton.nautscan.com/'],
  blockExplorerName: 'Nautilus Explorer',
}
