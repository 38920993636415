import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../components/Box";
import { Image } from "../../../../components/Image";
import { RefreshIcon, WalletFilledIcon, WarningIcon } from "../../../../components/Svg";
import { Colors } from "../../../../theme/types";
import { Variant, variants } from "./types";

const MenuIconWrapper = styled.div<{ borderColor: keyof Colors }>`
  align-items: center;
  // background-color: ${({ theme }) => theme.colors.background};
  // border-color: ${({ theme, borderColor }) => theme.colors[borderColor]};
  // border-radius: 50%;
  // border-style: solid;
  // border-width: 2px;
  display: flex;
  height: 32px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
  //z-index: 102;
`;

const ProfileIconWrapper = styled(Flex)`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
`;
//
// const ProfileIcon = styled(Image)`
//   left: 0;
//   position: absolute;
//   top: 0;
//   z-index: 102;
//
//   & > img {
//     border-radius: 50%;
//   }
// `;

export const NoProfileMenuIcon: React.FC<React.PropsWithChildren> = () => (
  <ProfileIconWrapper>
    <WalletFilledIcon style={{ background: "#0C846E" }} />
  </ProfileIconWrapper>

  // <div style={{ width: "100%", marginRight: "5px" }}>
  //   <WalletFilledIcon style={{ background: "#15D4B1" }} />
  // </div>
);

export const PendingMenuIcon: React.FC<React.PropsWithChildren> = () => (
  <ProfileIconWrapper>
    <RefreshIcon color="secondary" width="24px" spin />
  </ProfileIconWrapper>
);

export const WarningMenuIcon: React.FC<React.PropsWithChildren> = () => (
  <ProfileIconWrapper>
    <WarningIcon color="warning" width="24px" />
  </ProfileIconWrapper>
);

export const DangerMenuIcon: React.FC<React.PropsWithChildren> = () => (
  <ProfileIconWrapper>
    <WarningIcon color="failure" width="24px" />
  </ProfileIconWrapper>
);

const MenuIcon: React.FC<React.PropsWithChildren<{ avatarSrc?: string; variant: Variant; className?: string }>> = ({
  avatarSrc,
  variant,
  className,
}) => {
  if (variant === variants.DANGER) {
    return <DangerMenuIcon />;
  }

  if (variant === variants.WARNING) {
    return <WarningMenuIcon />;
  }

  if (variant === variants.PENDING) {
    return <PendingMenuIcon />;
  }

  if (!avatarSrc) {
    return null;
  }

  return (
    <ProfileIconWrapper>
      <Image src={avatarSrc} className={className} width={19} height={19} />
    </ProfileIconWrapper>
  );

  // <ProfileIcon src={avatarSrc} height={19} width={19} className={className} />;
};

export default MenuIcon;
