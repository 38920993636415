import { vars } from "@pancakeswap/ui/css/vars.css";
import { useIsMounted, useTheme } from "@pancakeswap/hooks";
import React from "react";
import styled from "styled-components";
import Container from "web/src/components/Layout/Container";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import { ArrowForwardIcon, LogoWithTextIcon } from "../Svg";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";
import { SkeletonV2 } from "../Skeleton";
import { useMatchBreakpoints } from "../../contexts";

const BottomFooter = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-self: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: ${({ isMobile }) => (isMobile ? "26px" : "8px")};
`;

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  ...props
}) => {
  const isMounted = useIsMounted();
  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledFooter
      data-theme="dark"
      p={["36px 16px", null, "66px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
      zIndex={0}
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        {/* <StyledIconMobileContainer display={["none", null, "none"]}>
          <LogoWithTextIcon width="130px" />
        </StyledIconMobileContainer>
        <Flex
          order={[2, null, 1]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          // mb={["28px", null, "24px"]}
        > */}
        {/*  {items?.map((item) => ( */}
        {/*    <StyledList key={item.label}> */}
        {/*      <StyledListItem>{item.label}</StyledListItem> */}
        {/*      {item.items?.map(({ label, href, isHighlighted = false }) => ( */}
        {/*        <StyledListItem key={label}> */}
        {/*          {href ? ( */}
        {/*            <Link */}
        {/*              data-theme="dark" */}
        {/*              href={href} */}
        {/*              target="_blank" */}
        {/*              rel="noreferrer noopener" */}
        {/*              color={isHighlighted ? vars.colors.warning : "text"} */}
        {/*              fontSize={["16px", "16px", "14px", "16px"]} */}
        {/*            > */}
        {/*              {label} */}
        {/*            </Link> */}
        {/*          ) : ( */}
        {/*            <StyledText>{label}</StyledText> */}
        {/*          )} */}
        {/*        </StyledListItem> */}
        {/*      ))} */}
        {/*    </StyledList> */}
        {/*  ))} */}
        {/*  <Box display={["none", null, "block"]}> */}
        {/*    <LogoWithTextIcon width="160px" /> */}
        {/*  </Box> */}
        {/* </Flex> */}
        <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
        {/* <StyledToolsContainer
          data-theme="dark"
          order={[1, null, 3]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
        >
          <Flex order={[2, null, 1]} alignItems="center"> */}
        {/*    <SkeletonV2 variant="round" width="56px" height="32px" isDataReady={isMounted}> */}
        {/*      <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} /> */}
        {/*    </SkeletonV2> */}
        {/*    <LangSelector */}
        {/*      currentLang={currentLang} */}
        {/*      langs={langs} */}
        {/*      setLang={setLang} */}
        {/*      color="textSubtle" */}
        {/*      dropdownPosition="top-right" */}
        {/*    /> */}
        {/*  </Flex> */}
        {/*  <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center"> */}
        {/*    <Box mr="20px"> */}
        {/*      <CakePrice cakePriceUsd={cakePriceUsd} color="textSubtle" /> */}
        {/*    </Box> */}
        {/*    <Button */}
        {/*      data-theme={isDark ? "dark" : "light"} */}
        {/*      as="a" */}
        {/*      href={buyCakeLink} */}
        {/*      target="_blank" */}
        {/*      scale="sm" */}
        {/*      endIcon={<ArrowForwardIcon color="backgroundAlt" />} */}
        {/*    > */}
        {/*      {buyCakeLabel} */}
        {/*    </Button> */}
          {/* </Flex>
        </StyledToolsContainer> */}
        <BottomFooter isMobile={isMobile}>PoseiSwap © 2023</BottomFooter>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
