import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z" />
    // </Svg>

    <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.6667 9.75276C16.6667 9.59757 16.7325 9.44873 16.8497 9.33899C16.9669 9.22924 17.1259 9.16759 17.2917 9.16759C17.4574 9.16759 17.6164 9.22924 17.7336 9.33899C17.8508 9.44873 17.9167 9.59757 17.9167 9.75276V14.6292C17.9167 15.8142 16.8906 16.7748 15.625 16.7748H4.375C3.10938 16.7748 2.08334 15.8142 2.08334 14.6292V4.68128C2.08334 3.49631 3.10938 2.53566 4.375 2.53566H9.79166C9.95743 2.53566 10.1164 2.59731 10.2336 2.70705C10.3508 2.81679 10.4167 2.96563 10.4167 3.12083C10.4167 3.27603 10.3508 3.42487 10.2336 3.53461C10.1164 3.64435 9.95743 3.706 9.79166 3.706H4.375C4.09874 3.706 3.83379 3.80876 3.63844 3.99166C3.44309 4.17456 3.33334 4.42262 3.33334 4.68128V14.6292C3.33334 14.8879 3.44309 15.1359 3.63844 15.3188C3.83379 15.5017 4.09874 15.6045 4.375 15.6045H15.625C15.9013 15.6045 16.1662 15.5017 16.3616 15.3188C16.5569 15.1359 16.6667 14.8879 16.6667 14.6292V9.75276ZM15.8452 4.68128L14.0915 2.93669C13.9779 2.82365 13.9169 2.67299 13.9219 2.51786C13.927 2.36272 13.9976 2.21582 14.1183 2.10945C14.2391 2.00309 14.4 1.94599 14.5657 1.95071C14.7314 1.95543 14.8883 2.02158 15.0019 2.13462L17.7469 4.86541C18.1223 5.23875 17.8394 5.85163 17.2917 5.85163H14.1544C12.5592 5.85163 11.25 7.15382 11.25 8.77748V12.2885C11.25 12.4437 11.1842 12.5926 11.0669 12.7023C10.9497 12.812 10.7908 12.8737 10.625 12.8737C10.4592 12.8737 10.3003 12.812 10.1831 12.7023C10.0659 12.5926 10 12.4437 10 12.2885V8.77748C10 6.52282 11.8513 4.68128 14.1544 4.68128H15.8452H15.8452Z"
      />
    </Svg>
  );
};

export default Icon;
