import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
    //   />
    // </Svg>

    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V13.5C0 13.8978 0.158035 14.2794 0.43934 14.5607C0.720644 14.842 1.10218 15 1.5 15H19.5C19.8978 15 20.2794 14.842 20.5607 14.5607C20.842 14.2794 21 13.8978 21 13.5V1.5C21 1.10218 20.842 0.720644 20.5607 0.43934C20.2794 0.158035 19.8978 0 19.5 0ZM16.5 8H20V9H16.5C16.1022 9 15.7206 8.84196 15.4393 8.56066C15.158 8.27936 15 7.89782 15 7.5C15 7.10218 15.158 6.72064 15.4393 6.43934C15.7206 6.15804 16.1022 6 16.5 6H20V7H16.5C16.3674 7 16.2402 7.05268 16.1464 7.14645C16.0527 7.24021 16 7.36739 16 7.5C16 7.63261 16.0527 7.75979 16.1464 7.85355C16.2402 7.94732 16.3674 8 16.5 8ZM1.5 1H19.5C19.6326 1 19.7598 1.05268 19.8536 1.14645C19.9473 1.24021 20 1.36739 20 1.5V3H1V1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1ZM19.5 14H1.5C1.36739 14 1.24021 13.9473 1.14645 13.8536C1.05268 13.7598 1 13.6326 1 13.5V4H20V5H16.5C15.837 5 15.2011 5.26339 14.7322 5.73223C14.2634 6.20107 14 6.83696 14 7.5C14 8.16304 14.2634 8.79893 14.7322 9.26777C15.2011 9.73661 15.837 10 16.5 10H20V13.5C20 13.6326 19.9473 13.7598 19.8536 13.8536C19.7598 13.9473 19.6326 14 19.5 14Z"
        fill="white"
      />
    </svg>
  );
};

export default Icon;
