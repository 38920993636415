import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { createWallets, getDocLink } from 'config/wallet'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
// eslint-disable-next-line import/extensions
// @ts-ignore
import { useActiveHandle } from 'hooks/useEagerConnect.bmp'
import styled from 'styled-components'
import React, { useMemo, useState } from 'react'
import { useConnect } from 'wagmi'
import { StyledUserMenu } from '@pancakeswap/uikit/src/widgets/Menu/components/UserMenu'
import Trans from './Trans'

interface ConnectWalletButtonProps extends ButtonProps {
  isForHeaderMenu?: boolean
}

const HeaderStyledUserMenu = styled(StyledUserMenu)`
  padding-left: 8px;
  background-color: var(--colors-primary);
  color: var(--colors-contrast);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const ConnectWalletButton = ({ children, isForHeaderMenu, ...props }: ConnectWalletButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()
  const [open, setOpen] = useState(false)
  const forHeaderMenu = isForHeaderMenu ?? false

  const docLink = useMemo(() => getDocLink(code), [code])

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      setOpen(true)
    }
  }

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  return (
    <>
      {forHeaderMenu ? (
        <HeaderStyledUserMenu onClick={handleClick} paddingX={3}>
          {children || <Trans>Connect Wallet</Trans>}
        </HeaderStyledUserMenu>
      ) : (
        <Button onClick={handleClick} {...props}>
          {children || <Trans>Connect Wallet</Trans>}
        </Button>
      )}
      <WalletModalV2
        docText={t('Learn How to Connect')}
        docLink={docLink}
        isOpen={open}
        wallets={wallets}
        login={login}
        onDismiss={() => setOpen(false)}
      />
    </>
  )
}

export default ConnectWalletButton
